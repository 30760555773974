import React, { useEffect, useRef, useState } from "react";
import Modal from "@mui/material/Modal";
import { modalHeader } from "./ModalConstants";
import { Close } from "@mui/icons-material";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import CircularProgress from "@mui/material/CircularProgress";

import MyQuill from "../../Pages/Products/MyQuill";
import { useDispatch, useSelector } from "react-redux";

import { createCategoryRequest } from "../../../redux/Actions/Products/createCategory";

function ModalAddCategory(props) {
  const dispatch = useDispatch();
  const handleClose = () => props.open(false);

  const loginSchema = Yup.object().shape({
    name: Yup.string().required("Please enter name"),
  });
  const [nutritional, setNutritional] = useState(false);
  const [display, setDisplay] =useState(false);


  const [editorContent, setEditorContent] = useState("");

  const handleChildData = (data) => {
    setEditorContent(data);
  };


  const handleSwitchDisplay = () => {
    setDisplay(!display);
  };

  const res = useSelector((state) => state.createCategory);
  const handleSwitchChange = () => {
    setNutritional(!nutritional);
  };

  const [selectedImage, setSelectedImage] = useState(null);
  const [sendImage, setSendImage] = useState(null);

  const fileInputRef = useRef(null);

  const handleClearImage = () => {
    setSelectedImage(null);
  };
  const handleEditClick = () => {
    fileInputRef.current.click();
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      setSendImage(file);

      reader.readAsDataURL(file);
    }
  };

  const [flag, setFlag] = useState(true);
  const [imageFlag, setImageFlag] = useState(false);
  const [descriptionFlag, setDescriptionFlag] = useState(false);

  useEffect(() => {
    if (res?.data || res?.error) {
      setFlag(true);
    }
  }, [res]);

  useEffect(() => {
    if (selectedImage) {
      setImageFlag(false);
    }
    if (editorContent) {
      setDescriptionFlag(false);
    }
  }, [selectedImage, editorContent]);
  const handleSubmit = (values, { setSubmitting }) => {
    const categoryData = {
      name: values.name,
      description: editorContent,

      display: display,
      nutritional: nutritional,
    };

    if (editorContent) {
      if (selectedImage) {
        setFlag(false);

        const newFormData = new FormData();
        newFormData.append("categoryRequest", JSON.stringify(categoryData));
        newFormData.append("categoryImage", sendImage);
        dispatch(createCategoryRequest(newFormData));
      } else {
        setImageFlag(true);
      }
    } else {
      setDescriptionFlag(true);
    }
  };

  if (res.data?.data.status === 201) {
    Swal.fire({
      title: "Category created successfully",
      icon: "success",
    }).then(() => {
      window.location.reload();
    });
  }

  if (res?.error) {
    Swal.fire({
      title: res?.error.data.response.data.errorMessage,
      icon: "error",
    }).then(() => {});
  }
  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="mainModal"
      >
        <div className="editProductMainBody2">
          <div className="editCategoryBody">
            <div className="modalHeader1">
              <div
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className="modalHeaderText"
                sx={modalHeader}
              >
                Add New Category
              </div>

              <button className="closeButtonModal" onClick={handleClose}>
                <Close />
              </button>
            </div>
            <Formik
              initialValues={{ name: "", Parent: "", Status: "" }}
              validationSchema={loginSchema}
              onSubmit={handleSubmit}
            >
              {
                <Form className="editCategoryFormikBody">
                  <div className="editCategoryName">Category Name</div>

                  <label style={{ width: "90%" }}>
                    <Field
                      type="text"
                      name="name"
                      placeholder="Category  Name"
                      className="editCategoryField"
                    />
                    <ErrorMessage
                      className="errorMessage"
                      name="name"
                      component="div"
                    />
                  </label>
                  <div className="editCategoryDescription">Description</div>
                  <div style={{ width: "80%" }}>
                    <MyQuill sendContentToParent={handleChildData} />
                  </div>
                  {descriptionFlag && (
                    <div style={{ marginTop: "5em" }} className="errorMessage">
                      Please enter description
                    </div>
                  )}

                  <div className="editSwitchBody1">
                    <div style={{ marginTop: "3vw" }}>Nutritional Value </div>
                    <div className="editCategorySwitchButton1">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={nutritional}
                              onChange={handleSwitchChange}
                            />
                          }
                        />
                      </FormGroup>
                    </div>
                  </div>

                  <div className="editSwitchBody1">
                    <div className="editCatsegorySwitchName">
                      Display on Landing page{" "}
                    </div>
                    <div className="editCategorySwitchButton1">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={display}
                              onChange={handleSwitchDisplay}
                            />
                          }
                        />
                      </FormGroup>
                    </div>
                  </div>

                  <div className="editCategoryDescription">Image </div>
                  {imageFlag && (
                    <div className="errorMessage">Please select a image</div>
                  )}

                  <div>
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleImageChange}
                    />
                    {selectedImage && (
                      <div>
                        <img
                          src={selectedImage}
                          alt="Selected"
                          style={{ maxWidth: "100%", maxHeight: "200px" }}
                        />
                      </div>
                    )}
                  </div>
                  <div style={{ marginTop: "2em" }}>
                  {!selectedImage && (
                    <button
                    type="button"
                      className="editButtonProduct"
                      onClick={handleEditClick}
                    >
                      Add Image
                    </button>
                  )}
                   
                    <button
                      onClick={handleClearImage}
                      className="clearButtonProduct"
                    >
                      Clear{" "}
                      {/* <NotInterestedIcon
                      style={{
                        fontSize: "1rem",
                        marginTop: "-0.7em",
                        marginLeft: "0.3em",
                      }}
                    /> */}
                    </button>
                  </div>
                  <div style={{marginTop:"2em"}} className="buttonCreate">

                  <button
                    disabled={!flag}
                    className="editSubmitButtons"
                    type="submit"
                  >
                    {flag ? " Create" : <CircularProgress />}
                  </button>
                  </div>
                </Form>
              }
            </Formik>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ModalAddCategory;
