import { Box, TextField } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import Dropdown from "react-multilevel-dropdown";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { checkEmailAgentRequest } from "../../../redux/Actions/Agents/checkEmailAgentAction";
import { programAndPlanRequest } from "../../../redux/Actions/Appointments/programAndPlan";
import { relationshipRequest } from "../../../redux/Actions/Appointments/relationship";
import { userDetailRequest } from "../../../redux/Actions/Appointments/userDetail";
import { AddBookingRequest } from "../../../redux/Actions/Booking/AddBookingAction";
import { viewAllCityRequest } from "../../../redux/Actions/City/viewAllCityAction";
import { getRelationRequest } from "../../../redux/Actions/Employee/getRelationAction";
import storage from "../../../Utils/storage";
import "./ModalAddBooking.css";
import { UserContext } from "../../../App";
import CircularProgress from "@mui/material/CircularProgress";


const loginSchema = Yup.object().shape({
  // address: Yup.string().required("Required Field"),
  pinCode: Yup.string().required("Required Field"),
});

function ModalAddBooking(props) {
  const dispatch = useDispatch();

  const role = useContext(UserContext);

  const [patientNumberError, setPatientNumberError] = useState(false);
  const [value, setValue] = React.useState("");
  const [email, setEmail] = useState("");
  const [select, setSelect] = useState(null);
  const [relationID, setRelation] = useState(null);
  const packageNameObject = [];
  const packageNameArray = [];
  const testsNameObject = [];
  const testsNameArray = [];

  const [attributeId, setAttributeId] = useState("");
  const [index, setIndex] = useState("");
  const [indexSub, setIndexSub] = useState("");
  const [cityIdd, setCityIdd] = useState("");

  useEffect(() => {
    dispatch(viewAllCityRequest());
  }, []);

  const getMyTestsAndPackage = useSelector(
    (state) => state.getMyTestsAndPackage
  );
  const allUserDetail = useSelector((state) => state.userDetail);
  const allUserDetailReduced = allUserDetail?.data?.action;

  useEffect(() => {
    if (email.length > 3) {
      dispatch(userDetailRequest(email));
    }
  }, [email]);

  getMyTestsAndPackage?.data?.data.data.myTestResponseDtoList.map((item) => {
    testsNameObject.push(item);
  });
  getMyTestsAndPackage?.data?.data.data.myPackageResponseDtoList.map((item) => {
    packageNameObject.push(item);
  });
  testsNameObject.map((item) => {
    testsNameArray.push({ label: item.name, id: item.id });
  });
  packageNameObject.map((item) => {
    packageNameArray.push({ label: item.name, id: item.id });
  });

  const handleCity = (event) => {
    setCityIdd(event.target.value);
  };

  const emailDetail = useSelector((state) => state.checkEmailAgent);
  const viewallCity = useSelector((state) => state.viewAllCity);
  var someDate = new Date(value);
  someDate = someDate.getTime();

  const programAndPlan = useSelector(
    (state) => state.programAndPlan?.data?.data?.data
  );

  const datas = {
    id: allUserDetailReduced?.data?.data?.id,
    uuid: "ee5413dd-eb09-4a99-92d0-a4fc6d92a5e9",
  };
  const relationship = useSelector(
    (state) => state.relationship?.data?.data?.data
  );

  useEffect(() => {
    if (emailDetail.data?.data.status === 200) {
      dispatch(getRelationRequest(emailDetail?.data?.data?.data?.data?.id));
    }
  }, [emailDetail]);

  useEffect(() => {
    if (allUserDetailReduced?.data?.data) {
      dispatch(programAndPlanRequest(datas));
    }
  }, [allUserDetailReduced?.data?.data?.id]);

  useEffect(() => {
    setCityIdd(allUserDetailReduced?.data?.data?.cityId);
    if (allUserDetailReduced?.data?.data) {
      dispatch(programAndPlanRequest(datas));
    }
  }, [allUserDetailReduced]);

  function preventnumberinput(e) {
    var keycode = e.keycode ? e.keycode : e.which;
    if ((keycode > 47 && keycode < 58) || (keycode > 95 && keycode < 107)) {
      e.preventDefault();
    }
  }

  const handleClose = () => {
    props.open(false);
    window.location.reload();
  };
  const [check, setCheck] = useState();
  const [time, setTime] = useState();
  const [flag, setFlag] = useState(true);
  const updateRes = useSelector((state) => state.AddBooking)

  useEffect(()=>{
    if(updateRes?.data || updateRes?.error)
    {

      setFlag(true);
    }

  },[updateRes])


  const handleSubmit = (values) => {

    if (values.contactNumber === "") {
      setPatientNumberError(true);
      return;
    } else {
      setPatientNumberError(false);
    }
    if (!value) {
      setTime(true);
    } else {
      setTime(false);
    }
    if (!item) {
      setCheck(true);
    } else {
      setCheck(false);
    }

    const finaldata = {
      address: values.address,
      contactNumber: values.contactNumber,
      // attributeType: select === 1 ? "INDIVIDUAL_TEST" : "PACKAGE",
      cityId: cityIdd,
      patientId: allUserDetailReduced.data?.data.id,
      pinCode: values.pinCode,
      relationId: relationID,
      timeSlot: someDate,
      plan: programAndPlan?.data[index]?.plan,
      packageUuid: [
        programAndPlan?.data[index]?.assignedAttributeResponseDto[indexSub]?.id,
      ],
      programOrPlanUuid: programAndPlan?.data[index]?.uuid,
      // testId: programAndPlan?.data[index]?.assignedAttributeResponseDto[indexSub]?.id,
      userPlanVersion: programAndPlan?.data[index]?.userVersion,
      version: programAndPlan?.data[index]?.version,
    };
    if (value && item && !patientNumberError) {
    setFlag(false)

      dispatch(AddBookingRequest(finaldata));
    }
  };

  const AddBooking = useSelector((state) => state.AddBooking);

  if (
    AddBooking?.error === null &&
    AddBooking?.data?.data.status === 201 &&
    AddBooking?.data?.data.data.message === "Booking added successfully"
  ) {
    // props.open(false);

    Swal.fire({
      title: "Booking added successfully",
      icon: "success",
    }).then(() => {
      window.location.href = "/Booking";
    });
  }
  useEffect(() => {
    if (
      AddBooking?.error != null
      // &&
      // AddBooking?.data?.data.data.message === "Booking added successfully"
    ) {
      // props.open(false);
      {
        AddBooking?.error?.data?.response?.data?.errorMessage
          ? Swal.fire({
              title: AddBooking?.error?.data?.response?.data?.errorMessage,
              icon: "error",
            }).then(() => {})
          : Swal.fire({
              title: AddBooking?.error?.data?.response?.data?.address,
              icon: "error",
            }).then(() => {});
      }
    }
  }, [AddBooking]);

  function handleTestsPackage(key, value) {
    setSelect(value.id);
  }
  function handleTests(key, value) {
    setAttributeId(value.id);
  }
  function handlePackage(key, value) {
    setAttributeId(value.id);
  }
  const [isCheck, setIsCheck] = useState(false);
  const handleCheckEmail = (event) => {
    setIsCheck(true);
    setEmail(event.target.value);
  };

  const handleRelation = (event) => {
    setRelation(event.target.value);
  };

  useEffect(() => {
    if (email.includes("@")) {
      dispatch(checkEmailAgentRequest(email));
    }
  }, [email]);

  const top = [
    { label: "Tests", id: 1 },
    { label: "Package", id: 2 },
  ];

  const [item, setItem] = useState();
  const doSomething = (index, item) => {
    setItem(item);
    setIndex(index);
  };
  const [planCheck, setPlanCheck] = useState(false);

  const subIndex = (subIndex) => {
    setIndexSub(subIndex);
    setPlanCheck(true);
    const data = {
      id: allUserDetailReduced?.data?.data?.id,
      uuid: programAndPlan?.data[index]?.uuid,
      version:
        programAndPlan?.data[index]?.plan === false
          ? null
          : programAndPlan?.data[index]?.version,
      userVersion:
        programAndPlan?.data[index]?.plan === false
          ? null
          : programAndPlan?.data[index]?.userVersion,
    };
    dispatch(relationshipRequest(data));
  };

  return (
    <div>
      <div className="headingBooking">Add New Booking</div>
      <Box className="bookingStyle1">
        <Formik
          initialValues={{
            customerName: allUserDetailReduced?.data?.data?.name,
            customerNumber: email.includes("@")
              ? allUserDetailReduced?.data?.data?.number
              : allUserDetailReduced?.data?.data?.email,
            address: allUserDetailReduced?.data?.data?.address,
            pinCode: "",
            contactNumber: allUserDetailReduced?.data?.data?.number
              ? allUserDetailReduced?.data?.data?.number
              : "",
          }}
          enableReinitialize={true}
          validationSchema={loginSchema}
          onSubmit={handleSubmit}
        >
          {
            <Form>
              <div className="inputFieldBody">
                <div className="inputFieldsContainer">
                  <label>
                    <Field
                      autocomplete="off"
                      name="customerEmail"
                      value={email}
                      placeholder="Customer Email Or Number"
                      onChange={handleCheckEmail}
                      className="inputField"
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="customerEmail"
                      component="div"
                    />
                    {allUserDetailReduced?.data?.data?.data?.message !=
                      null && (
                      <>
                        <div className="emailNotRegistered">
                          Email Or Number Not Register
                        </div>
                      </>
                    )}
                  </label>
                  <div className="BookingNumberData">
                    {allUserDetailReduced?.data?.data.number ? (
                      <label>
                        Patient Number :{" "}
                        {allUserDetailReduced?.data?.data.number}
                      </label>
                    ) : (
                      ""
                    )}
                    {allUserDetailReduced?.data?.data.email ? (
                      <label>
                        Patient Email : {allUserDetailReduced?.data?.data.email}
                      </label>
                    ) : (
                      ""
                    )}
                  </div>

                  {allUserDetailReduced?.data?.data && isCheck && (
                    <label>
                      <Field
                        type=""
                        name="customerName"
                        placeholder="Customer Name"
                        className="inputField"
                        disabled={true}
                        onKeyDown={(event) => preventnumberinput(event)}
                      />
                      <ErrorMessage
                        className="errorMessageModalValidation"
                        name="customerName"
                        component="div"
                      />
                    </label>
                  )}
                  {allUserDetailReduced?.data?.data && isCheck && (
                    <label>
                      <Field
                        type="text"
                        name="address"
                        placeholder="Address"
                        className="inputField"
                        // disabled={true}
                      />
                      {/* <ErrorMessage
                        className="errorMessageModalValidation"
                        name="address"
                        component="div"
                      /> */}
                    </label>
                  )}
                  {role === "SUPER_ADMIN" && (
                    <>
                      {allUserDetailReduced?.data?.data && isCheck && (
                        <label>
                          <select
                            onChange={(e) => handleCity(e)}
                            name="city"
                            className="inputField"
                          >
                            {allUserDetailReduced?.data?.data.cityId !==
                            null ? (
                              <option
                                value={allUserDetailReduced?.data?.data.cityId}
                                selected
                                disabled
                              >
                                {allUserDetailReduced?.data?.data.cityName}
                              </option>
                            ) : (
                              <option value="" disabled selected>
                                Select City
                              </option>
                            )}
                            {viewallCity?.data?.data?.data?.data?.map(
                              (item, index) => (
                                <option key={index} value={item.id}>
                                  {item.name}
                                </option>
                              )
                            )}
                          </select>
                        </label>
                      )}{" "}
                    </>
                  )}
                  {allUserDetailReduced?.data?.data && isCheck && (
                    <label>
                      <Field
                        type="number"
                        name="pinCode"
                        placeholder="pincode"
                        className="inputField"
                        // disabled={true}
                      />
                      <ErrorMessage
                        className="errorMessageModalValidation"
                        name="pinCode"
                        component="div"
                      />
                    </label>
                  )}

                  {allUserDetailReduced?.data?.data && isCheck && (
                    <>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <Dropdown title="Program And Plan">
                          {programAndPlan?.data?.map((item, index) => (
                            <Dropdown.Item
                              onClick={() => doSomething(index, item)}
                            >
                              {item.name}
                              <Dropdown.Submenu>
                                {programAndPlan?.data[
                                  index
                                ]?.assignedAttributeResponseDto?.map(
                                  (item, index) => (
                                    <Dropdown.Item
                                      onClick={() => subIndex(index)}
                                    >
                                      {item.name}{" "}
                                      &nbsp;&nbsp;&nbsp;Available&nbsp;-&nbsp;
                                      {item.available}
                                      &nbsp;&nbsp;&nbsp;Used&nbsp;-&nbsp;
                                      {item.used}
                                    </Dropdown.Item>
                                    // <Dropdown.Item>Subitem 1</Dropdown.Item>
                                  )
                                )}
                              </Dropdown.Submenu>
                            </Dropdown.Item>
                          ))}
                        </Dropdown>
                        {/* <div className="planBody"> */}
                        {/* {index &&( */}
                        <>
                          <div
                            style={{
                              marginLeft: "4vw",
                            }}
                          >
                            {/* OPD: */}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginLeft: "3vw",
                              marginTop: "-1vw",
                            }}
                          >
                            <div>
                              {programAndPlan?.data[index]?.name}{" "}
                              {
                                // programAndPlan?.data[index]?.assignedAttributeResponseDto?.available
                              }
                            </div>
                            <div style={{ marginTop: "1vw" }}>
                              {
                                programAndPlan?.data[index]
                                  ?.assignedAttributeResponseDto[indexSub]?.name
                              }{" "}
                              &nbsp;&nbsp;&nbsp;Available- &nbsp;&nbsp;
                              {
                                programAndPlan?.data[index]
                                  ?.assignedAttributeResponseDto[indexSub]
                                  ?.available
                              }{" "}
                              &nbsp;&nbsp;&nbsp; Used- &nbsp;&nbsp;
                              {
                                programAndPlan?.data[index]
                                  ?.assignedAttributeResponseDto[indexSub]?.used
                              }{" "}
                              &nbsp;&nbsp;&nbsp;
                              {/* {
                                  programAndPlan?.data[index]
                                    ?.assignedAttributeResponseDto[0]?.used
                                } */}
                            </div>
                          </div>
                        </>
                        {/* )} */}
                      </div>
                      {check ? (
                        <>
                          {" "}
                          <div className="errorMessageModalValidation">
                            Required Field
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}

                  {allUserDetailReduced?.data?.data && planCheck && (
                    <label>
                      <Field
                        type="number"
                        name="contactNumber"
                        placeholder="Patient Contact Number"
                        className="inputField"
                      />
                      {patientNumberError ? (
                        <div className="errorMessageModalValidation">
                          Required Field
                        </div>
                      ) : (
                        ""
                      )}
                    </label>
                  )}

                  {allUserDetailReduced?.data?.data && planCheck && (
                    <label>
                      <select
                        onChange={(e) => handleRelation(e)}
                        name="relation"
                        className="inputField"
                      >
                        <option value="0" selected>
                          Myself
                        </option>
                        {relationship?.data?.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.name}&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                            {item.relation}
                            &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;({item.age})
                          </option>
                        ))}
                      </select>
                    </label>
                  )}
                  {allUserDetailReduced?.data?.data && planCheck && (
                    <>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                          minDate={new Date()}
                          renderInput={(props) => (
                            <TextField
                              {...props}
                              style={{ width: "40%", borderRadius: "12px" }}
                            />
                          )}
                          label="Select Date and Time"
                          value={value}
                          onChange={(newValue) => {
                            setValue(newValue);
                          }}
                        />
                        <ErrorMessage
                          className="errorMessageModalValidation"
                          name="dateTime"
                          component="div"
                        />
                      </LocalizationProvider>
                      {time ? (
                        <>
                          {" "}
                          <div className="errorMessageModalValidations">
                            Required Field
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                  {allUserDetailReduced?.data?.data && planCheck && (
                    <button
                      type="submit"
                      style={{ width: "40%" }}
                      className="modalButtonAppointment"
                      disabled={!flag}
                      //
                    >
                      {flag ? "      Add Booking" : <CircularProgress />}
                    </button>
                  )}
                </div>
              </div>
            </Form>
          }
        </Formik>
      </Box>
      {/* </Modal> */}
    </div>
  );
}

export default ModalAddBooking;
