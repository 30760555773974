import React, { useEffect, useState } from "react";
import Close from "@mui/icons-material/Close";
import "./ServicesNew.css";
//import { viewAllServicesNameAndUuidSuccess } from "../redux/Actions/Services/viewAllServicesNameAndUuidAction";

function Services(prop) {
  const [servicesAttribute, setServicesAttribute] = useState([]);
  const servicesNames = [];
  const servicesInfoObject = [];
  


  const {
    handleClose,
    servicesInfo,
    handleServicesAttributeData,
    servicesAttributeData
  } = prop;
  const [attributeCountNew, setAttributeCountNew] = useState();
  useEffect(() => {
    servicesAttribute.push(servicesInfoObject)

    setServicesAttribute([...servicesInfoObject])
   
  }, []);
  servicesInfo.attributeResponseDtoList.map((item) => {
    servicesInfoObject.push(item);
    servicesNames.push(item.name);
  });

  let handleChange = (e, attributeName, x,desc) => 
  {
        let newFormValues = [
            {
                [e.target.name]: parseInt(e.target.value),
                ["attributeUuid"]: x.toString(),
                ["attributeType"]: "INDIVIDUAL_TEST",
                ["exclusive"]: true,
                ["attributeName"]: attributeName,
                ["attributeDescription"]:desc
            }
        ]

        let ffval= {
           ["serviceName"]: servicesInfo.name,
           ["serviceUuid"]: servicesInfo.serviceUUID,
           ["attributeCountDtoList"]: newFormValues,
        }
        handleServicesAttributeData(ffval);
  };
  return (
    <div className="cashlessOpd">
      <div className="cashlessOpd-top">
        <div className="section1" value={servicesInfo.serviceUUID} >
          {servicesInfo.name}
        </div>
        <div className="section3">
          <button
            className="templateClose" 
            onClick={() => {
              handleClose(servicesInfo.serviceUUID);
            }}
          >
            <Close />
          </button>
        </div>
      </div>
      <div className="cashlessOpd-bottom">
      {servicesInfoObject.map((item, index) => {
            return (
              <div className="services_attribute_new" key={item.attributeId}>
                <div className="leftside" key={item.attributeId}>{item.attributeName}</div>
                <div className="rightside" key={item.attributeId}>
                  <input
                    name="attributeCount"
                    key={item.attributeId}
                    value={attributeCountNew}
                    min="0"
                    type="number"
                    placeholder="count"
                    onChange={(e)=>setAttributeCountNew(e.target.value)}
                    onBlur={(e) =>
                      handleChange(e, item.attributeName, item.attributeId,item.attributeDescription )
                    }
                    onKeyDown={(e) =>
                      ["e", "E", "+", "-"].includes(e.key) &&
                      e.preventDefault()
                    }
                    onWheel={(event) => event.currentTarget.blur()}
                  />
                </div>
              </div>
            );
        })}
      </div>
    </div>
  );
}

export default Services;