import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { addPlanRequest } from "../../../redux/Actions/Plan/addPlanAction";
import { viewAllPackageNamesRequest } from "../../../redux/Actions/Program Template/Package/viewAllPackageNamesAction";
import { viewAllServicesNameAndUuidRequest } from "../../../redux/Actions/Program Template/Services/viewAllServicesNameAndUuidAction";
import Footer from "../../Organisms/Footer/FooterUpper";
import DiagnosticService from "../Program Template/DiagnosticService.js";
import HRAService from "../Program Template/HRAService";
import PharmacyService from "../Program Template/PharmacyService";
import ServicesNew from "../Program Template/ServicesNew";
import "../Program Template/ServicesNew.css";
import "./AddPlan.css";
import { viewAllCityRequest } from "../../../redux/Actions/City/viewAllCityAction.js";

function AddPlan() {
  const dispatch = useDispatch();

  const [twelveMonths, setTwelveMonths] = useState(true);
  const [sixMonths, setSixMonths] = useState(true);
  const [threeMonths, setThreeMonths] = useState(true);
  const [FeaturedPackage, setFeaturedPackage] = useState(false);
  const [autoGenerateCardNumber, setAutoGenerateCardNumber] = useState(true);
  const [twelveMonthsPrice, setTwelveMonthsPrice] = useState("");
  const [sixMonthsPrice, setSixMonthsPrice] = useState("");
  const [threeMonthsPrice, setThreeMonthsPrice] = useState("");
  const [twelveMonthsGST, setTwelveMonthsGST] = useState("");
  const [sixMonthsGST, setSixMonthsGST] = useState("");
  const [threeMonthsGST, setThreeMonthsGST] = useState("");
  const [children, setChildren] = useState(0);
  const [relations, setRelation] = React.useState([]);
  const [showRelations, setShowRelation] = React.useState([]);

  const [selectedCity, setSelectedCity] = useState([])

  const [check3, setCheck3] = React.useState(false);
  const [check2, setCheck2] = React.useState(false);
  const [check1, setCheck1] = React.useState(false);

  const INITIAL_STATE = {};
  const [FormValidation, setFormValidation] = useState([INITIAL_STATE]);
  const [inputList, setInputList] = useState([
    { parameterName: "", parameterDescription: "" },
  ]);
console.log("showRelations",showRelations);
  const [servicesComponent, setServicesComponent] = useState([]);
  const ServiceApiDataArray = [];
  const [updatedServicesAttributeData, setUpdatedServicesAttributeData] =
    useState([]);
  const [servicesAttributeData, setServicesAttributeData] = useState([]);

  const [planName, setPlanName] = useState("");
  const [planDescription, setPlanDescription] = useState("");

  useEffect(() => {
    dispatch(viewAllServicesNameAndUuidRequest());
    dispatch(viewAllPackageNamesRequest());
    dispatch(viewAllCityRequest())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [error, setError] = useState();
  const [zeroError, setZeroError] = useState();
  const [empty, setEmpty] = useState();
  const [emptyPharmacy, setEmptyPharmacy] = useState();

  function TopSubLimiterror(bool) {
    setError(bool);
  }

  function ZeroTopSubLimiterror(bool) {
    setZeroError(bool);
  }
  function EmptyTopSubLimiterror(bool) {
    setEmpty(bool);
  }
  function EmptyPharmacyTopSubLimiterror(bool) {
    setEmptyPharmacy(bool);
  }
  const viewServiceNames = useSelector(
    (state) => state.viewAllServicesNameAndUuidReducer
  );
  const viewAllCity = useSelector((state) => state.viewAllCity)

  viewServiceNames?.data?.data?.data?.map((item) =>
    ServiceApiDataArray.push(item)
  );

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const dropdown = [
    "FATHER",
    "MOTHER",
    "SPOUSE",
    "SON",
    "DAUGHTER",
    "MOTHER_IN_LAW",
    "FATHER_IN_LAW",
  ];

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setRelation(typeof value === "string" ? value.split(",") : value);
  };

  const handleChangeRelations = (event) => {
    const {
      target: { value },
    } = event;
    setShowRelation(typeof value === "string" ? value.split(",") : value);
  };

  const handleCityChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedCity(typeof value === "string" ? value.split(",") : value);
  }

  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };
  const handleAddClick = () => {
    setInputList([...inputList, { parameterName: "" }]);
    FormValidation.push(INITIAL_STATE);
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  const handleName = (event) => {
    setPlanName(event.target.value);
  };

  const handleDescription = (event) => {
    setPlanDescription(event.target.value);
  };

  const handleTwelveMonthsPrice = (event) => {
    setTwelveMonthsPrice(event.target.value);
  };

  const handleSixMonthsPrice = (event) => {
    setSixMonthsPrice(event.target.value);
  };

  const handleThreeMonthsPrice = (event) => {
    setThreeMonthsPrice(event.target.value);
  };

  const handleTwelveMonthsGST = (event) => {
    setTwelveMonthsGST(event.target.value);
  };

  const handleSixMonthsGST = (event) => {
    setSixMonthsGST(event.target.value);
  };

  const handleThreeMonthsGST = (event) => {
    setThreeMonthsGST(event.target.value);
  };

  const handleCheckBox = (event) => {
    if (event.target.value === "12M") {
      setTwelveMonthsPrice("");
      setTwelveMonthsGST("");
      twelveMonths === true ? setTwelveMonths(false) : setTwelveMonths(true);
    }

    if (event.target.value === "6M") {
      setSixMonthsPrice("");
      setSixMonthsGST("");
      sixMonths === true ? setSixMonths(false) : setSixMonths(true);
    }

    if (event.target.value === "3M") {
      setThreeMonthsPrice("");
      setThreeMonthsGST("");
      threeMonths === true ? setThreeMonths(false) : setThreeMonths(true);
    }
  };

  const handleFeaturedPlan = (event) => {
    if (event.target.value === "FeaturedPackage") {
      FeaturedPackage === true
        ? setFeaturedPackage(false)
        : setFeaturedPackage(true);
    }
  };

  const handleAutoCardNumberGeneration = (event) => {
    if (event.target.value === "autoGenerateCardNumber") {
      setAutoGenerateCardNumber((prevState) => !prevState);
    }
  };


  const updateRes = useSelector((state) => state.addPlan)
  const [flag, setFlag] = useState(true);
  useEffect(() => {
    if (updateRes?.data || updateRes?.error) {

      setFlag(true);
    }

  }, [updateRes])

  const handleSubmit = () => {
    let submitdata = {};
    let otherService = [];
    let finalotherService = [];
    let twelveMonthsFP = 0;
    let sixMonthsFP = 0;
    let threeMonthsFP = 0;

    let twelveMonthsGSTFinal = 0;
    let sixMonthsGSTFinal = 0;
    let threeMonthsGSTFinal = 0;
    let typeList = [];
   
    const additionalServices = servicesComponent
      .filter((item) => item.attributeResponseDtoList.length === 0)
      .map((item) => item.serviceUUID);

    if (check1 === true) {
      typeList.push("TYPE_A");
    }
    if (check2 === true) {
      typeList.push("TYPE_B");
    }
    if (check3 === true) {
      typeList.push("TYPE_C");
    }

    inputList.map((index) => {
      otherService.push({
        otherServiceName: index.parameterName,
        otherServiceDescription: index.parameterDescription,
      });
    });

    otherService.map((item, index) => {
      if (!item.otherServiceDescription && item.otherServiceName) {
        item.otherServiceDescription = null;
        finalotherService.push(item);
      }
      if (item.otherServiceName && item.otherServiceDescription) {
        finalotherService.push(item);
      }
    });

    if (twelveMonthsPrice !== "") {
      twelveMonthsFP = parseInt(twelveMonthsPrice);
    }
    if (sixMonthsPrice !== "") {
      sixMonthsFP = parseInt(sixMonthsPrice);
    }
    if (threeMonthsPrice !== "") {
      threeMonthsFP = parseInt(threeMonthsPrice);
    }

    if (twelveMonthsGST !== "") {
      twelveMonthsGSTFinal = parseInt(twelveMonthsGST);
    }
    if (sixMonthsGST !== "") {
      sixMonthsGSTFinal = parseInt(sixMonthsGST);
    }
    if (threeMonthsGST !== "") {
      threeMonthsGSTFinal = parseInt(threeMonthsGST);
    }

    const filteredServicesAttributeData = [];

    updatedServicesAttributeData.forEach((item) => {
      if (item.serviceUuid !== "b5aaaf86-e1f3-4acb-97a2-d5198ee4e7bb") {
        const validAttributeCountDtoList = item.attributeCountDtoList.filter(
          (ele) => {
            return !isNaN(ele.attributeCount);
          }
        );

        if (validAttributeCountDtoList.length > 0) {
          filteredServicesAttributeData.push({
            ...item,
            attributeCountDtoList: validAttributeCountDtoList,
          });
        }
      } else {
        const validAttributeCountDtoList = item.attributeCountDtoList.filter(
          (ele) => {
            return !isNaN(ele.subLimit) && !isNaN(ele.topLimit);
          }
        );

        if (validAttributeCountDtoList.length > 0) {
          filteredServicesAttributeData.push({
            ...item,
            attributeCountDtoList: validAttributeCountDtoList,
          });
        }
      }
    });

    const cityIds = viewAllCity.data?.data.data.data
      .filter(city => selectedCity.includes(city.name))
      .map(city => city.id);

    submitdata = {
      planName,
      planDescription,
      filteredServicesAttributeData,
      children,
      relations,
      twelveMonthsFP,
      sixMonthsFP,
      threeMonthsFP,
      finalotherService,
      FeaturedPackage,
      autoGenerateCardNumber,
      twelveMonthsGSTFinal,
      sixMonthsGSTFinal,
      threeMonthsGSTFinal,
      typeList,
      additionalServices,
      cityIds,
      showRelations
    };

    if (planName === "") {
      Swal.fire({
        title: "Please Add Plan Name",
        icon: "error",
      });
    } else if (planDescription === "") {
      Swal.fire({
        title: "Please Add Plan Description",
        icon: "error",
      });
    } else if (twelveMonths === false && twelveMonthsPrice === "") {
      Swal.fire({
        title: "Please Add 12 Months plan's price",
        icon: "error",
      });
    } else if (sixMonths === false && sixMonthsPrice === "") {
      Swal.fire({
        title: "Please Add 6 Months plan's price",
        icon: "error",
      });
    } else if (threeMonths === false && threeMonthsPrice === "") {
      Swal.fire({
        title: "Please Add 3 Months plan's price",
        icon: "error",
      });
    } else if (
      sixMonths === true &&
      threeMonths === true &&
      twelveMonths === true
    ) {
      Swal.fire({
        title: "Please Add a plan",
        icon: "error",
      });
    } else if (typeList.length === 0) {
      Swal.fire({
        title: "Please Add a plan Type",
        icon: "error",
      });
    } else if (filteredServicesAttributeData.length === 0) {
      Swal.fire({
        title: "Services Not filled / Selected",
        icon: "error",
      });
    } else if (error === true) {
      Swal.fire({
        title: "Please fill pharmacy details ",
        icon: "error",
      });
    } else if (zeroError === true) {
      Swal.fire({
        title: "Please fill pharmacy details ",
        icon: "error",
      });
    }
    else if (
      (relations.includes("SON") || relations.includes("DAUGHTER")) &&
      submitdata.children === 0
    ) {
      Swal.fire({
        title: "Please fill Number of Children",
        icon: "error",
      });
    } else if (cityIds.length <= 0) {
      Swal.fire({
        title: "Please select a city",
        icon: "error",
      });
    } else {
      setFlag(false)
      dispatch(addPlanRequest(submitdata));
    }
  };
  const addplan = useSelector((state) => state.addPlan);

  if (
    addplan?.data?.data.status === 201 &&
    addplan?.data?.data?.data.message !== ""
  ) {
    Swal.fire({
      title: "Plan added successfully",
      icon: "success",
    }).then(() => {
      window.location.reload();
    });
  }
  useEffect(() => {
    if (
      addplan?.error !== null
    ) {
      Swal.fire({
        title: `${addplan?.error?.data?.response?.data.errorMessage}`,
        icon: "error",
      });
    }
  }, [addplan?.error]);

  const handleClose = (componentsServiceUid) => {
    setEmptyPharmacy(false);

    let newServicesComponent = servicesComponent.filter(
      (item) => item.serviceUUID !== componentsServiceUid
    );
    let newUpdatedServicesAttributeData = updatedServicesAttributeData.filter(
      (item) => item.serviceUuid !== componentsServiceUid
    );
    let newServicesAttributeData = servicesAttributeData.filter(
      (item) => item.serviceUuid !== componentsServiceUid
    );
    setServicesAttributeData(newServicesAttributeData);
    setUpdatedServicesAttributeData(newUpdatedServicesAttributeData);
    setServicesComponent(newServicesComponent);
  };

  const handleCloseAttribute = (componentsServiceUid) => {
    let newUpdatedServicesAttributeData = updatedServicesAttributeData.filter(
      (item) => item.serviceUuid !== componentsServiceUid
    );
    let newServicesAttributeData = servicesAttributeData.filter(
      (item) => item.serviceUuid !== componentsServiceUid
    );
    setServicesAttributeData(newServicesAttributeData);
    setUpdatedServicesAttributeData(newUpdatedServicesAttributeData);
  };

  const handleServicesAttributeData = (servicesAttributeDataInput) => {
    if (servicesAttributeData.length === 0) {
      servicesAttributeData.push(servicesAttributeDataInput);
    } else {
      let boolT = servicesAttributeData.some((item) => {
        if (item.serviceUuid === servicesAttributeDataInput.serviceUuid) {
          return true;
        }
      });
      if (boolT === true) {
        servicesAttributeData.map((item, index) => {
          if (servicesAttributeDataInput.serviceUuid === item.serviceUuid) {
            servicesAttributeData.splice(index, 1);
            servicesAttributeData.push(servicesAttributeDataInput);
          }
        });
      } else {
        servicesAttributeData.push(servicesAttributeDataInput);
      }
    }

    servicesAttributeData.map((item) => {
      if (updatedServicesAttributeData.length === 0) {
        updatedServicesAttributeData.push(item);
      } else {
        let boolT = updatedServicesAttributeData.some((element) => {
          if (element.serviceUuid === item.serviceUuid) {
            return true;
          } else {
            return false;
          }
        });
        if (boolT === true) {
          updatedServicesAttributeData.map((individual, index) => {
            if (individual.serviceName === item.serviceName) {
              updatedServicesAttributeData.splice(index, 1);
              updatedServicesAttributeData.push(item);
              setUpdatedServicesAttributeData([
                ...updatedServicesAttributeData,
              ]);
            }
          });
        } else {
          updatedServicesAttributeData.push(item);
          setUpdatedServicesAttributeData([...updatedServicesAttributeData]);
        }
      }
    });
  };

  function handleCheckboxChange() {
    const typeA = document.getElementById("typeA");
    const typeB = document.getElementById("typeB");
    const typeC = document.getElementById("typeC");

    if (typeA.checked) {
      setCheck1(true);
    } else {
      setCheck1(false);
    }
    if (typeB.checked) {
      setCheck2(true);
    } else {
      setCheck2(false);
    }
    if (typeC.checked) {
      setCheck3(true);
    } else {
      setCheck3(false);
    }
  }

  return (
    <>
      <div className="AddPlanWrapper">
        <div className="AddPlanHeader">
          <h1 className="AddPlanHeaderText">Create Plan</h1>
        </div>

        <div className="AddPlanMain">
          <div className="AddPlanMainLeft">
            <input
              className="AddPlanMainLeftInputField"
              placeholder="Plan Name"
              id="planName"
              value={planName}
              onChange={(e) => {
                handleName(e);
              }}
            />
            <textarea
              placeholder="Plan Description"
              id="planDescription"
              type="textArea"
              className="PlanDescriptionTextArea"
              onChange={(e) => {
                handleDescription(e);
              }}
            />
            <div className="AddPlanSubscription">
              <div className="AddPlanSubscriptionName">12 Months</div>
              <input
                className="AddPlanInputField"
                type="checkbox"
                id="12M"
                value="12M"
                onClick={(e) => {
                  handleCheckBox(e);
                }}
              />
              <input
                className={
                  twelveMonths === false
                    ? "AddPlanAmountField"
                    : "AddPlanAmountDisabled"
                }
                type="number"
                placeholder="Amount"
                value={twelveMonthsPrice}
                disabled={twelveMonths === true ? true : false}
                onKeyDown={(e) =>
                  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                }
                onWheel={(event) => event.currentTarget.blur()}
                onChange={(e) => {
                  handleTwelveMonthsPrice(e);
                }}
              />
              <input
                className={
                  twelveMonths === false
                    ? "AddPlanAmountField"
                    : "AddPlanAmountDisabled"
                }
                type="number"
                placeholder="GST"
                value={twelveMonthsGST}
                disabled={twelveMonths === true ? true : false}
                onKeyDown={(e) =>
                  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                }
                onWheel={(event) => event.currentTarget.blur()}
                onChange={(e) => {
                  handleTwelveMonthsGST(e);
                }}
              />
            </div>
            <div className="AddPlanSubscription">
              <div className="AddPlanSubscriptionName">06 Months</div>
              <input
                className="AddPlanInputField"
                type="checkbox"
                id="6M"
                value="6M"
                onClick={(e) => {
                  handleCheckBox(e);
                }}
              />
              <input
                className={
                  sixMonths === false
                    ? "AddPlanAmountField"
                    : "AddPlanAmountDisabled"
                }
                type="number"
                placeholder="Amount"
                value={sixMonthsPrice}
                disabled={sixMonths === true ? true : false}
                onKeyDown={(e) =>
                  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                }
                onWheel={(event) => event.currentTarget.blur()}
                onChange={(e) => {
                  handleSixMonthsPrice(e);
                }}
              />
              <input
                className={
                  sixMonths === false
                    ? "AddPlanAmountField"
                    : "AddPlanAmountDisabled"
                }
                type="number"
                placeholder="GST"
                value={sixMonthsGST}
                disabled={sixMonths === true ? true : false}
                onKeyDown={(e) =>
                  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                }
                onWheel={(event) => event.currentTarget.blur()}
                onChange={(e) => {
                  handleSixMonthsGST(e);
                }}
              />
            </div>
            <div className="AddPlanSubscription">
              <div className="AddPlanSubscriptionName">03 Months</div>
              <input
                className="AddPlanInputField"
                type="checkbox"
                id="3M"
                value="3M"
                onClick={(e) => {
                  handleCheckBox(e);
                }}
              />
              <input
                className={
                  threeMonths === false
                    ? "AddPlanAmountField"
                    : "AddPlanAmountDisabled"
                }
                type="number"
                placeholder="Amount"
                value={threeMonthsPrice}
                disabled={threeMonths === true ? true : false}
                onKeyDown={(e) =>
                  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                }
                onWheel={(event) => event.currentTarget.blur()}
                onChange={(e) => {
                  handleThreeMonthsPrice(e);
                }}
              />

              <input
                className={
                  threeMonths === false
                    ? "AddPlanAmountField"
                    : "AddPlanAmountDisabled"
                }
                type="number"
                placeholder="GST"
                value={threeMonthsGST}
                disabled={threeMonths === true ? true : false}
                onKeyDown={(e) =>
                  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                }
                onWheel={(event) => event.currentTarget.blur()}
                onChange={(e) => {
                  handleThreeMonthsGST(e);
                }}
              />
            </div>
            <div className="relationsComponent">
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="demo-multiple-checkbox-label ">
                  Select City
                </InputLabel>
                <Select
                  id="demo-multiple-checkbox"
                  multiple
                  value={selectedCity}
                  onChange={handleCityChange}
                  input={<OutlinedInput label=" Select City" />}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                >
                  {viewAllCity.data?.data.data.data.map((name, index) => (
                    <MenuItem key={index} value={name.name}>
                      <Checkbox checked={selectedCity.indexOf(name.name) > -1} />
                      <ListItemText primary={name.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="relationsComponent">
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="demo-multiple-checkbox-label ">
                  Select Relation
                </InputLabel>
                <Select
                  id="demo-multiple-checkbox"
                  multiple
                  value={relations}
                  onChange={handleChange}
                  input={<OutlinedInput label=" Select relations" />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {dropdown.map((dropdown) => (
                    <MenuItem key={dropdown} value={dropdown}>
                      <Checkbox checked={relations.indexOf(dropdown) > -1} />
                      <ListItemText primary={dropdown} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            &nbsp;
            {(relations.includes("SON") || relations.includes("DAUGHTER")) && (
              <input
                as="Textarea"
                rows="1"
                cols="60"
                name="NumberChildren"
                type="number"
                onKeyDown={(e) =>
                  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                }
                placeholder="Total number of children"
                className="AddPlanMainLeftInputFieldChild"
                onBlur={(e) => setChildren(e.target.value)}
              />
            )}
            <div className="attributeCardBox">
              {inputList.map((x, i) => {
                return (
                  <>
                    <div className="addParameterWrapper">
                      <div className="otherservices">
                        <input
                          name="parameterName"
                          placeholder="Service Name (optional)"
                          id="parameterName"
                          className="inputFieldAddParameterFirstPlans1"
                          value={x.parameterName}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                        <input
                          name="parameterDescription"
                          placeholder="Description (optional)"
                          id="parameterDescription"
                          className="inputFieldAddParameterFirstPlans2"
                          value={x.parameterDescription}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                      </div>
                      <p className="errorMessageModalValidatio">
                        {FormValidation[i]?.parameterName}
                      </p>
                      <div className="addParameterCross">
                        {inputList.length !== 1 && (
                          <button
                            className="addParameterCrossButton"
                            onClick={() => handleRemoveClick(i)}
                          >
                            X
                          </button>
                        )}
                      </div>
                    </div>

                    <div>
                      {inputList.length - 1 === i && (
                        <button
                          onClick={() => {
                            handleAddClick();
                          }}
                          className="addParameterButton"
                        >
                          +Add Other Service
                        </button>
                      )}
                    </div>
                  </>
                );
              })}
            </div>
            <div className="hospitalType">
              <div className="hospitalTypeHeader">
                <p>Plan Type</p>
              </div>
              <div className="hospitalTypeFooter">
                <div className="hospitalTypeFooterChild1">
                  <input
                    type="checkbox"
                    id="typeA"
                    name="typeA"
                    value="Type_A"
                    onChange={handleCheckboxChange}
                  />
                  <label for="typeA">Type A</label>
                </div>
                <div className="hospitalTypeFooterChild1">
                  <input
                    type="checkbox"
                    id="typeB"
                    name="typeB"
                    value="Type_B"
                    onChange={handleCheckboxChange}
                  />
                  <label for="typeB">Type B</label>
                </div>
                <div className="hospitalTypeFooterChild1">
                  <input
                    type="checkbox"
                    id="typeC"
                    name="typeC"
                    value="Type_C"
                    onChange={handleCheckboxChange}
                  />
                  <label for="typeC">Type C</label>
                </div>
              </div>
            </div>
            <div className="relationsComponent">
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="demo-multiple-checkbox-label ">
                  Show Relation
                </InputLabel>
                <Select
                  id="demo-multiple-checkbox"
                  multiple
                  value={showRelations}
                  onChange={handleChangeRelations}
                  input={<OutlinedInput label=" Show relations" />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {dropdown.map((dropdown) => (
                    <MenuItem key={dropdown} value={dropdown}>
                      <Checkbox checked={showRelations.indexOf(dropdown) > -1} />
                      <ListItemText primary={dropdown} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            {servicesComponent.map((item) => {
              if (item.serviceUUID === "ee5413dd-eb09-4a99-92d0-a4fc6d92a5e9") {
                return (
                  <DiagnosticService
                    servicesInfo={item}
                    key={item.serviceUUID}
                    handleClose={handleClose}
                    handleServicesAttributeData={handleServicesAttributeData}
                    handleCloseAttribute={handleCloseAttribute}
                  />
                );
              }
              if (item.serviceUUID === "1dbcc55e-3dec-4e07-8c2a-e222631afebb") {
                return (
                  <HRAService
                    servicesInfo={item}
                    key={item.serviceUUID}
                    handleClose={handleClose}
                    handleServicesAttributeData={handleServicesAttributeData}
                    handleCloseAttribute={handleCloseAttribute}
                  />
                );
              }
              if (item.serviceUUID === "b5aaaf86-e1f3-4acb-97a2-d5198ee4e7bb") {
                return (
                  <>
                    <PharmacyService
                      servicesInfo={item}
                      key={item.serviceUUID}
                      handleClose={handleClose}
                      handleServicesAttributeData={handleServicesAttributeData}
                      handleCloseAttribute={handleCloseAttribute}
                      TopSubLimiterror={TopSubLimiterror}
                      ZeroTopSubLimiterror={ZeroTopSubLimiterror}
                      EmptyTopSubLimiterror={EmptyTopSubLimiterror}
                      EmptyPharmacyTopSubLimiterror={
                        EmptyPharmacyTopSubLimiterror
                      }
                    />
                    {zeroError ? (
                      <p
                        style={{
                          color: "red",
                          marginLeft: "3.81em",
                          marginTop: "-2em",
                        }}
                      >
                        Total Limit/Sub Limit cannot be negative/zero
                      </p>
                    ) : (
                      ""
                    )}
                    {empty ? (
                      <p
                        style={{
                          color: "red",
                          marginLeft: "3.81em",
                          marginTop: "-2em",
                          paddingTop: "1em",
                        }}
                      >
                        Total Limit/Sub Limit cannot be null/Empty
                      </p>
                    ) : (
                      ""
                    )}
                    {error ? (
                      <p
                        style={{
                          color: "red",
                          marginLeft: "3.81em",
                          marginTop: "-2em",
                          paddingTop: "1em",
                        }}
                      >
                        Total Limit has to greater than Sub Limit
                      </p>
                    ) : (
                      ""
                    )}
                  </>
                );
              }
              // if (item.serviceUUID === "3089855d-85a1-4c2f-9538-c8276cd76768") {
              //   return (
              //     <DiscountService
              //       servicesInfo={item}
              //       handleClose={handleClose}
              //       handleServicesAttributeData={handleServicesAttributeData}
              //       handleCloseAttribute={handleCloseAttribute}
              //     />
              //   );
              // }
              else {
                return (
                  <ServicesNew
                    servicesInfo={item}
                    key={item.serviceUUID}
                    handleClose={handleClose}
                    handleServicesAttributeData={handleServicesAttributeData}
                    handleCloseAttribute={handleCloseAttribute}
                  />
                );
              }
            })}
          </div>

          <div className="AddPlanMainRight">
            <div className="AddPlanMainRightHeader">Add Services</div>

            <hr className="AddPlanSeperator" />

            <div className="AddPlanMainRightList">
              {ServiceApiDataArray.map((item) => {
                return (
                  <div className="AddPlanMainRightListIndividual">
                    <div
                      className="AddPlanMainRightListButtonPlus"
                      onClick={() => {
                        if (
                          servicesComponent.length === 0 ||
                          servicesComponent.length >= 0
                        ) {
                          let boo = servicesComponent.some((element) => {
                            if (element.serviceUUID === item.serviceUuid) {
                              return true;
                            } else {
                              return false;
                            }
                          });
                          if (!boo) {
                            setServicesComponent([
                              ...servicesComponent,
                              {
                                name: item.name,
                                serviceUUID: item.serviceUuid,
                                attributeResponseDtoList:
                                  item.attributeResponseDtoList,
                              },
                            ]);
                          }
                        }
                      }}
                    >
                      +
                    </div>
                    <h1 className="AddPlanMainRightListName">{item.name}</h1>
                  </div>
                );
              })}
            </div>

            <hr className="AddPlanSeperator" />

            <div className="FeaturedPackCheckDiv">
              <input
                className="AddPlanInpufdtField"
                type="checkbox"
                id="FeaturedPackage"
                value="FeaturedPackage"
                onClick={(e) => {
                  handleFeaturedPlan(e);
                }}
              />
              <h1 className="FeaturedPackCheckText">Mark as featured</h1>
            </div>

            <div className="FeaturedPackCheckDiv">
              <input
                className="AddPlanInpufdtField"
                type="checkbox"
                id="autoGenerateCardNumber"
                value="autoGenerateCardNumber"
                checked={autoGenerateCardNumber}
                onClick={(e) => {
                  handleAutoCardNumberGeneration(e);
                }}
              />
              <h1 className="FeaturedPackCheckText">
                Auto Generate Card Number{" "}
              </h1>
            </div>

            <button className=" addPlanSubmit" disabled={!flag} onClick={handleSubmit}>
              {flag ? "    submit" : <CircularProgress />}
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AddPlan;
