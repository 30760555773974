import { Box, Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import { DataGrid } from "@mui/x-data-grid";
import React, { useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AssignCardSubscription from "./AssignCardSubscription";
function PlanDetailsList(props) {
  const [openAssignCardModal, SetopenAssignCardModal] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState();
  function handleClose(bool) {
    SetopenAssignCardModal(bool);
  }
  const handleClick = (id) => {
    setSubscriptionId(id)
    SetopenAssignCardModal(true);
  };

  const columns = [
    {
      field: "name",
      headerClassName: "super-app-theme--header",
      headerName: "Plan Name",
      flex: 1,
      minWidth: 200,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "startDate",
      headerClassName: "super-app-theme--header",
      headerName: "Start Date",
      flex: 1,
      minWidth: 200,

      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <>
            <Typography>
              {new Date(params.row["startDate"]).toLocaleString()}
            </Typography>
          </>
        );
      },
    },
    {
      field: "endDate",
      headerClassName: "super-app-theme--header",
      headerName: "End Date",
      flex: 1,
      minWidth: 200,

      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <>
            <Typography>
              {new Date(params.row["endDate"]).toLocaleString()}
            </Typography>
          </>
        );
      },
    },
    {
      field: "price",
      headerClassName: "super-app-theme--header",
      headerName: " Price",
      flex: 1,
      sortable: false,
      minWidth: 200,

      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <>
            <div>₹{params.row["price"]}/-</div>
          </>
        );
      },
    },
    {
      field: "programStatus",
      headerClassName: "super-app-theme--header",
      headerName: " Status",
      minWidth: 100,

      flex: 0.5,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            {params.row["status"] === false && (
              <Typography>
                <Chip label="Inactive" color="error" />
              </Typography>
            )}
            {params.row["status"] === true && (
              <Typography>
                <Chip label="Active" color="primary" />
              </Typography>
            )}

            {params.row["status"] === "Upcoming" && (
              <Typography>
                <Chip label="Upcoming" color="warning" />
              </Typography>
            )}
          </>
        );
      },
    },

    {
      field: "View",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (cellValues) => {
        return (
          <div>
            {cellValues.row.cardNumber == null ? <>

              <VisibilityIcon
                sx={{ color: "#52608E", marginRight: 2, cursor: "pointer" }}
                onClick={() => handleClick(cellValues.row.id)}
              />
            </> : <>

              {cellValues.row.cardNumber}
            </>}


          </div>
        );
      },
    },
  ];
  return (
    <div>
      {openAssignCardModal && (
        <AssignCardSubscription
          openAssignCardModal={openAssignCardModal}
          handleClose={handleClose}
          Subscriptionid={subscriptionId}
          userId={props.userId}
        />
      )}

      <Box
        sx={{
          height: 200,
          width: "100%",
        }}
      >
        {props?.data && (
          <DataGrid
            sx={{
              color: "#1D2334",
              "& .super-app-theme--header": {
                backgroundColor: "#52608E",
                color: "#F2EFEA",
                "& > .MuiDataGrid-columnSeparator": {
                  visibility: "hidden",
                },
              },
              "&:hover:not(.Mui-disabled)": {
                cursor: "pointer",
              },
              ".MuiTablePagination-toolbar": {
                alignItems: "baseline",
                marginTop: "5%",
              },
            }}
            getRowId={(row) => row.name + row.startDate}
            disableColumnMenu
            rows={props?.data}
            columns={columns}
            components={{
              Footer: "none",
            }}
          />
        )}
      </Box>
    </div>
  );
}

export default PlanDetailsList;
