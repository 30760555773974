import React, { useEffect, useRef, useState } from "react";
import "./AddProducts.css";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import MyQuill from "./MyQuill";
import Footer from "../../Organisms/Footer/FooterUpper";
import { useDispatch, useSelector } from "react-redux";
import { getRelatedProductRequest } from "../../../redux/Actions/Product/getRelatedProductAction";
import { getBrandDropdownRequest } from "../../../redux/Actions/Product/getBrandDropdownAction";
import { getUnitsRequest } from "../../../redux/Actions/Product/getUnitsAction";
import CloseIcon from "@mui/icons-material/Close";
import { getCategoryDropdownRequest } from "../../../redux/Actions/Product/getCategoryDropdownAction";
import { getSubCategoryDropdownRequest } from "../../../redux/Actions/Product/getSubCategoryDropdownAction";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { addProductRequest } from "../../../redux/Actions/Product/addProductAction";
import swal from "sweetalert";
import Loader from "../../../assets/loader.gif";
import Chip from "@mui/material/Chip";

function AddProducts() {
  const dispatch = useDispatch();
  const [Description, setDescription] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [nutritionalDescription, setNutritionalDescription] = useState("");
  const [selectedImageFile, setSelectedImageFile] = useState([]);
  const [selectedImage, setSelectedImage] = useState([]);
  const [selectRelatedProductName, setRelatedProductName] = useState([]);

  const [selectBrand, setSelectBrand] = useState();
  const [selectCategory, setSelectCategory] = useState();
  const [selectSubCategory, setSubSelectCategory] = useState();
  const [selectRelatedProduct, setRelatedProduct] = useState([]);
  const [inputList, setInputList] = useState([
    {
      price: "",
      unit: "",
      productUnitEnum: "",
    },
  ]);

  const productDescription = (data) => {
    setDescription(data);
  };

  const shortProductDescription = (data) => {
    setShortDescription(data);
  };

  const NutritionalDescription = (data) => {
    setNutritionalDescription(data);
  };

  function handleDeleteRelatedProduct(item, index) {
    selectRelatedProductName.splice(index, 1);
    selectRelatedProduct.splice(index, 1);
    setRelatedProduct((prevValue) => [...prevValue]);
    setRelatedProductName((prevValue) => [...prevValue]);
  }

  useEffect(() => {
    dispatch(getRelatedProductRequest());
    dispatch(getBrandDropdownRequest());
    dispatch(getUnitsRequest());
    dispatch(getCategoryDropdownRequest());
  }, []);

  useEffect(() => {
    if (selectCategory) {
      dispatch(getSubCategoryDropdownRequest(selectCategory));
    }
  }, [selectCategory]);

  const getRelatedProduct = useSelector((state) => state.getRelatedProduct);
  const getBrandDropdown = useSelector((state) => state.getBrandDropdown);
  const getUnits = useSelector((state) => state.getUnits);
  const getCategoryDropdown = useSelector((state) => state.getCategoryDropdown);
  const getSubCategoryDropdown = useSelector(
    (state) => state.getSubCategoryDropdown
  );
  const addProduct = useSelector((state) => state.addProduct);

  function handleSubmit(values) {
    const ProductData = {
      name: values.productName,
      brandId: values.Brand,
      categoryId: values.Category,
      subCategoryId: values.SubCategory,
      relatedProductId: selectRelatedProduct,
      productPriceRequestDtoList: inputList,
      nutritional: nutritionalDescription,
      description: Description,
      shortDescription: shortDescription,
    };

    if (ProductData.name === "") {
      swal({
        title: "Please Fill Product Name !!",
        icon: "info",
      });
    } else if (ProductData.description === "") {
      swal({
        title: "Please Fill The Description !!",
        icon: "info",
      });
    } else if (ProductData.shortDescription === "") {
      swal({
        title: "Please Fill The Short Description !!",
        icon: "info",
      });
    } else if (ProductData.categoryId === "") {
      swal({
        title: "Please Select Category!!",
        icon: "info",
      });
    } else if (
      ProductData.categoryId !== "" &&
      getSubCategoryDropdown.data?.data.data.data.length !== 0 &&
      ProductData.subCategoryId === ""
    ) {
      swal({
        title: "Please Select Sub-Category !!",
        icon: "info",
      });
    } else if (
      ProductData.subCategoryId !== "" &&
      ProductData.nutritional === ""
    ) {
      getSubCategoryDropdown.data?.data.data.data.map((item) => {
        if (selectSubCategory === item.id && item.nutritionalValue === true) {
          if (ProductData.nutritional === "") {
            swal({
              title: "Please Fill Nutrional !!",
              icon: "info",
            });
          }
        }
      });
    } else if (ProductData.brandId === "") {
      swal({
        title: "Please Select Any Brand !!",
        icon: "info",
      });
    } else if (inputList) {
      const isInputListValid = inputList.every(
        (item) =>
          item.price.trim() !== "" &&
          item.unit.trim() !== "" &&
          item.productUnitEnum.trim() !== ""
      );
      if (!isInputListValid) {
        swal({
          title: "Please Fill All The Price Section Fields Carefully !!",
          icon: "info",
        });
        return;
      } else {
        const newFormData = new FormData();
        newFormData.append("productRequest", JSON.stringify(ProductData));
        selectedImageFile.map((item) => {
          newFormData.append("images", item);
        });

        dispatch(addProductRequest(newFormData));
      }
    }
  }
  const handleClearImage = () => {
    selectedImage.slice(0, selectedImage.length);
    setSelectedImage([]);
  };

  const fileInputRef = useRef(null);

  function handleRelatedProduct(e) {
    let selectedOption = e.target.selectedOptions[0];
    let selectedName = selectedOption.getAttribute("name");
    setRelatedProductName((prevValue) => [...prevValue, selectedName]);
  }

  const handleEditClick = () => {
    fileInputRef.current.click();
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setSelectedImageFile((prevValue) => [...prevValue, file]);
        setSelectedImage((prevValue) => [...prevValue, reader.result]);
      };

      reader.readAsDataURL(file);
    }
  };

  function removeImage(index) {
    selectedImage.map((item, inde) => {
      if (index === inde) {
        selectedImage.splice(inde, 1);
        selectedImageFile.splice(inde, 1);
        setSelectedImageFile((prevValue) => [...prevValue]);
        setSelectedImage((prevValue) => [...prevValue]);
      }
    });
  }

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const handleAddClick = () => {
    setInputList([
      ...inputList,
      {
        price: "",
        unit: "",
        productUnitEnum: "",
      },
    ]);
  };

  if (
    addProduct.data?.data.status === 201 &&
    addProduct.data?.data.data.message !== ""
  ) {
    swal({
      title: addProduct.data?.data.data.message,
      icon: "success",
    }).then(() => {
      addProduct.data.data.status = null;
      window.location.href = "/ProductListing";
    });
  }
  if (addProduct?.error) {
    swal({
      title: addProduct.error?.data?.response?.data?.errorMessage,
      icon: "error",
    }).then(() => {
      addProduct.error = null;
    });
  }

  return (
    <>
      <div className="editProductMainBody">
        <div className="editCategoryHeader">Add Product</div>
        <div className="editCategoryBody">
          <Formik
            initialValues={{
              productName: "",
              Brand: "",
              Category: "",
              SubCategory: "",
              RelatedProduct: [],
            }}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue }) => (
              <Form className="editCategoryFormikBody">
                <div className="editCategoryName">Product Name</div>

                <label style={{ width: "90%" }}>
                  <Field
                    type="text"
                    name="productName"
                    placeholder="Product  Name"
                    className="editCategoryField"
                    style={{ width: "100%" }}
                  />
                </label>
                <div
                  className="editCategoryName"
                  style={{ paddingTop: "1.3em" }}
                >
                  Short Description
                </div>
                <div style={{ width: "90%" }}>
                  <MyQuill sendContentToParent={shortProductDescription} />
                </div>
                <div className="editCategoryName">Description</div>
                <div style={{ width: "90%" }}>
                  <MyQuill sendContentToParent={productDescription} />
                </div>

                <div
                  style={{ marginTop: "3em" }}
                  className="editCategoryDescription editCategoryName"
                >
                  Category{" "}
                </div>

                <Field
                  as="select"
                  name="Category"
                  className="editCategoryFieldSelect"
                  id="selectedOption"
                  style={{ width: "90%" }}
                  onChange={(event) => {
                    setSelectCategory(event.target.value);
                    setFieldValue("Category", event.target.value);
                    setFieldValue("SubCategory", "");
                  }}
                >
                  <option value="" disabled selected={true}>
                    Category
                  </option>
                  {getCategoryDropdown.data?.data.data.data.map((item) => (
                    <option value={item.id} key={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Field>
                {getSubCategoryDropdown.data !== null &&
                getSubCategoryDropdown.data?.data.data.data.length !== 0 ? (
                  <>
                    <div
                      style={{ marginTop: "1em" }}
                      className="editCategoryDescription editCategoryName"
                    >
                      Sub Category{" "}
                    </div>

                    <Field
                      as="select"
                      name="SubCategory"
                      className="editCategoryFieldSelect"
                      style={{ width: "90%" }}
                      id="selectedOption"
                      onChange={(event) => {
                        setSubSelectCategory(event.target.value);
                        setFieldValue("SubCategory", event.target.value);
                      }}
                    >
                      <option value="" disabled selected={true}>
                        Sub Category
                      </option>
                      {getSubCategoryDropdown.data?.data.data.data.map(
                        (item) => (
                          <option value={item.id} key={item.id}>
                            {item.name}
                          </option>
                        )
                      )}
                    </Field>
                  </>
                ) : (
                  ""
                )}
                {selectSubCategory !== undefined ? (
                  getSubCategoryDropdown.data?.data.data.data.map((item) => {
                    if (
                      selectSubCategory === item.id &&
                      item.nutritionalValue === true
                    ) {
                      return (
                        <div style={{ marginBottom: "2em" }}>
                          <div className="editSwitchBody">
                            <div className="editCategorySwitchName editCategoryName">
                              Nutritional{" "}
                            </div>
                          </div>
                          <div style={{ width: "90%" }}>
                            <MyQuill
                              sendContentToParent={NutritionalDescription}
                            />
                          </div>
                        </div>
                      );
                    }
                  })
                ) : (
                  <></>
                )}

                <div
                  style={{}}
                  className="editCategoryDescription editCategoryName"
                >
                  Related Product{" "}
                </div>

                <Field
                  as="select"
                  name="RelatedProduct"
                  className="editCategoryFieldSelect"
                  style={{ width: "90%" }}
                  id="selectedOption"
                  onChange={(event) => {
                    if (!selectRelatedProduct.includes(event.target.value)) {
                      setRelatedProduct((prevValue) => [
                        ...prevValue,
                        event.target.value,
                      ]);
                      setFieldValue("RelatedProduct", event.target.value);
                      handleRelatedProduct(event);
                    }
                  }}
                >
                  <option value="" disabled selected={true}>
                    Related Product
                  </option>
                  {getRelatedProduct.data?.data.data.data.map((item) => (
                    <option value={item.id} key={item.id} name={item.name}>
                      {item.name}
                    </option>
                  ))}
                </Field>
                <div className="filterSelected" style={{ width: "90%" }}>
                  {selectRelatedProductName.length !== 0 ? (
                    <div className="filterSelectedChild">
                      <p>Related Product</p>
                      <div className="filterSelectedChildList">
                        {selectRelatedProductName?.map((item, index) => (
                          <>
                            <div key={index} className="chipDIvs">
                              <Chip
                                style={{
                                  backgroundColor: "#67E4CE",
                                  paddingTop: "1em",
                                  paddingBottom: "1em",
                                  height: "100%",
                                  width: "100%",
                                  color: "#52608E",
                                  fontWeight: "500",
                                  borderRadius: "10px",
                                }}
                                label={item}
                                onDelete={() =>
                                  handleDeleteRelatedProduct(item, index)
                                }
                              />
                            </div>
                          </>
                        ))}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="editCategoryDescription editCategoryName">
                  Brand{" "}
                </div>

                <Field
                  as="select"
                  name="Brand"
                  className="editCategoryFieldSelect"
                  style={{ width: "90%" }}
                  id="selectedOption"
                  onChange={(event) => {
                    setSelectBrand(event.target.value);
                    setFieldValue("Brand", event.target.value);
                  }}
                >
                  <option value="" disabled selected={true}>
                    Brand
                  </option>
                  {getBrandDropdown.data?.data.data.data.map((item) => (
                    <option value={item.id} key={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Field>
                <div className="addPriceWrapperParent">
                  {inputList.map((x, i) => {
                    return (
                      <>
                        <div className="addPriceWrapper">
                          <div className="addPriceWrapperFields">
                            <Field
                              type="number"
                              name="price"
                              placeholder="Price  "
                              className="PriceAndUnitsChildField"
                              value={x.price}
                              onKeyDown={(e) =>
                                ["e", "E", "+", "-"].includes(e.key) &&
                                e.preventDefault()
                              }
                              onWheel={(event) => event.currentTarget.blur()}
                              onChange={(e) => handleInputChange(e, i)}
                            />
                            <ErrorMessage
                              className="errorMessage"
                              name="price"
                              component="div"
                            />
                            <Field
                              type="number"
                              name="unit"
                              placeholder="Quantity"
                              className="PriceAndUnitsChildField"
                              onKeyDown={(e) =>
                                ["e", "E", "+", "-", "."].includes(e.key) &&
                                e.preventDefault()
                              }
                              onWheel={(event) => event.currentTarget.blur()}
                              value={x.unit}
                              onChange={(e) => handleInputChange(e, i)}
                            />
                            <ErrorMessage
                              className="errorMessage"
                              name="unit"
                              component="div"
                            />
                            <Field
                              as="select"
                              name="productUnitEnum"
                              className="PriceAndUnitsChildField"
                              id="selectedOption"
                              value={x.productUnitEnum}
                              onChange={(e) => handleInputChange(e, i)}
                            >
                              <option value="" disabled selected={true}>
                                Select Unit
                              </option>
                              {getUnits.data?.data.data.data.map((item) => (
                                <option value={item.id} key={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </Field>
                          </div>

                          <div className="closeIconPrice">
                            {inputList.length !== 1 && (
                              <CloseIcon
                                className="closeIconPriceButton"
                                onClick={() => handleRemoveClick(i)}
                              />
                            )}
                          </div>
                        </div>

                        <div className="addPriceButton">
                          <div style={{ width: "90%" }}></div>
                          <div
                            style={{
                              width: "10%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {inputList.length - 1 === i && (
                              <AddCircleIcon
                                onClick={() => {
                                  handleAddClick();
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>

                <div className="ProductImageBoxHeading">Image </div>

                <div>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleImageChange}
                  />
                </div>
                <div className="Add_Clear_Section">
                  <button type="button" onClick={handleEditClick}>
                    Add Image
                  </button>
                  <button type="button" onClick={handleClearImage}>
                    Clear All{" "}
                  </button>
                </div>

                <div className="productImageTilesArea">
                  {selectedImage?.map((item, index) => (
                    <div className="productImageTiles">
                      <CloseIcon
                        className="CloseButtonProductImage"
                        onClick={() => removeImage(index)}
                      />
                      <img
                        src={item}
                        style={{ width: "70%", height: "70%" }}
                        key={index}
                      />
                    </div>
                  ))}
                </div>
                {addProduct?.isLoading === true ? (
                  <div className="submitButtonArea">
                    <button type="submit" disabled={true}>
                      <img
                        alt="Loader"
                        src={Loader}
                        style={{ width: "15%", height: "90%" }}
                      />
                    </button>
                  </div>
                ) : (
                  <div className="submitButtonArea">
                    <button type="submit">Save</button>
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AddProducts;
